'use strict';

/**
 * @ngdoc service
 * @name skogkursSkogfondApp.persistService
 * @description
 * # persistService
 * Service in the skogkursSkogfondApp.
 */
angular.module('skogkursSkogfondApp')
  .service('persistService', function ( dataService ) {
    // AngularJS will instantiate a singleton by calling "new" on this function
    var config = {
      key : 'skogfondData'
    };

    var persistService = {
      persistData : function( data ){
        if( persistService.supportsStorage() ){
          window.localStorage.setItem( config.key, JSON.stringify( dataService.data ) );
        }
      },
      getData : function( data ){
        if( persistService.supportsStorage() ){
          if( window.localStorage[ config.key ] ){
            return window.localStorage[ config.key ];
          }else{
            return '{}';
          }
        }
        return '{}';
      },
      clearData : function(){
        if( persistService.supportsStorage() ){
          window.localStorage[ config.key ] = '{}';
          window.location.reload();
        }
      },
      supportsStorage : function(){
        try {
          return 'localStorage' in window && window.localStorage !== null;
        }catch(e){
          return false;
        }
      },
      hasUpdates : function(){
        return persistService.getData() !== JSON.stringify( dataService.data );
      },
      hasPersistedData : function(){
        return 'hogsts' in JSON.parse( persistService.getData() );
      }
    };


    if( persistService.supportsStorage() ){
      //Check for data
      var data = JSON.parse( persistService.getData() );
      if( 'hogsts' in data ){
        dataService.setData( data );
      }

      window.onbeforeunload = function(){
        if( persistService.hasUpdates() ){
          return 'Du mister tallene du har lagt inn hvis du forlatert nettstedet. Er du sikker på at du ønsker å forlate uten å lagre tallene dine i nettleseren?';
        }
      };
    }


    return persistService;

  });
