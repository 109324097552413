'use strict';

/**
 * @ngdoc overview
 * @name skogkursSkogfondApp
 * @description
 * # skogkursSkogfondApp
 *
 * Main module of the application.
 */

//External dependencies
window.jQuery = require('jquery');
require('angular');
require('bootstrap-sass');
require('angular-animate');
require('angular-cookies');
require('angular-resource');
require('angular-route');
require('angular-sanitize');
require('angular-touch');
window.attachFastClick = require('fastclick');


angular
  .module('skogkursSkogfondApp', [
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngRoute',
    'ngSanitize',
    'ngTouch'
  ])
  .config(function ($routeProvider) {
    $routeProvider
      .when('/', {
        template: require('../views/index.html'),
        controller: 'IndexCtrl'
      })
      .when('/main', {
        template: require('../views/main.html'),
        controller: 'MainCtrl'
      })
      .when('/about', {
        template: require('../views/about.html'),
        controller: 'AboutCtrl'
      })
      .when('/drift', {
        template: require('../views/drift.html'),
        controller: 'DriftCtrl'
      })
      .when('/resultat', {
        template: require('../views/resultat.html'),
        controller: 'ResultatCtrl'
      })
      .when('/investeringer', {
        template: require('../views/investeringer.html'),
        controller: 'InvesteringerCtrl'
      })
      .when('/skogfond', {
        template: require('../views/skogfond.html'),
        controller: 'SkogfondCtrl'
      })
      .otherwise({
        redirectTo: '/'
      });
  });



  //Attach fastclick
  jQuery(function(){
    window.attachFastClick( document.body );
  });

//App dependencies
require('./controllers/index.js');
require('./controllers/main.js');
require('./controllers/about.js');
require('./controllers/drift.js');
require('./controllers/resultat.js');
require('./controllers/investeringer.js');
require('./services/calculationservice.js');
require('./services/dataservice.js');
require('./controllers/skogfond.js');
require('./services/informationservice.js');
require('./directives/info.js');
require('./filters/formatamount.js');
require('./services/persistservice.js');
require('./directives/updateinput.js');
require('./directives/mode.js');



