'use strict';

/**
 * @ngdoc service
 * @name skogkursSkogfondApp.calculationService
 * @description
 * # calculationService
 * Service in the skogkursSkogfondApp.
 */
 angular.module('skogkursSkogfondApp')
 .service('calculationService', function ( $rootScope, dataService ) {
  return {
    calculateDrift : function(){
  		//if rootscope hogsts is set
  		var hogsts = dataService.data.hogsts;

  		var sumDrift = {
        bruttoinntektHogst  : 0,
        hogstKjoring        : 0,
        hogstTilskudd       : 0,
        totaltHogstVolum    : 0,
        snittVirkespris     : 0,
        snittUtgifter       : 0,
        skattInntekt        : 0,
        skattHogstKjoring   : 0,
        totalInntekt        : 0,
        totalHogstKjoring   : 0
      };

      //Cumulated sums
      var totalVolum = 0;
      for( var i=0; i<hogsts.length; i++ ){
        sumDrift.bruttoinntektHogst += (parseInt( hogsts[i].volum )*parseInt( hogsts[i].virkespris ));
        sumDrift.hogstKjoring += (parseInt( hogsts[i].volum )*parseInt( hogsts[i].utgifter ));
        sumDrift.hogstTilskudd += (parseInt( hogsts[i].volum )*parseInt( hogsts[i].tilskudd ));
        sumDrift.totaltHogstVolum += parseInt( hogsts[i].volum );
      }

      sumDrift.snittVirkespris = sumDrift.bruttoinntektHogst / sumDrift.totaltHogstVolum;
      sumDrift.snittUtgifter = sumDrift.hogstKjoring / sumDrift.totaltHogstVolum;
      sumDrift.skattInntekt = sumDrift.bruttoinntektHogst * dataService.data.skogfond.mva;
      sumDrift.skattHogstKjoring = sumDrift.hogstKjoring * dataService.data.skogfond.mva;
      sumDrift.totalInntekt = sumDrift.bruttoinntektHogst + sumDrift.skattInntekt;
      sumDrift.totalHogstKjoring = sumDrift.hogstKjoring + sumDrift.skattHogstKjoring;

      dataService.data.sumDrift = sumDrift;

      return sumDrift;
    },

    calculateInvesteringer : function(){
      var drift = this.calculateDrift();
      var tiltakSkogkultur = dataService.data.tiltakSkogkultur,
      tiltakSkogsveier = dataService.data.tiltakSkogsveier,
      tiltakAndre = dataService.data.tiltakAndre;

      var maxSkogfondssats = 0.4;

      var sumInvesteringer = {
        investeringer    : 0,
        tilskudd         : 0,
        skogfondssats    : 0,
        skogfondsbehov   : 0,
        skattbareInvesteringer: 0,
        skattInvesteringer : 0,
        totalInvesteringer : 0
      };

      var kostnad = 0;

      //Cumulated sums skogkultur
      var i = '';
      for( i in tiltakSkogkultur ){
      	kostnad = tiltakSkogkultur[i].areal * tiltakSkogkultur[i].kostnad;
        sumInvesteringer.investeringer += kostnad;
      	sumInvesteringer.tilskudd += kostnad*tiltakSkogkultur[i].tilskudd/100;
      }

      //Cumulated sums skogsveier
      for( i in tiltakSkogsveier ){
      	kostnad = tiltakSkogsveier[i].lengde * tiltakSkogsveier[i].kostnad;
        sumInvesteringer.investeringer += kostnad;
      	sumInvesteringer.tilskudd += kostnad*tiltakSkogsveier[i].tilskudd/100;
      }

      //Cumulated sums andre
      for( i in tiltakAndre ){
      	sumInvesteringer.investeringer += parseInt( tiltakAndre[i].kostnad );
      	sumInvesteringer.tilskudd += tiltakAndre[i].kostnad*tiltakAndre[i].tilskudd/100;
      }

      //In case mva still is in data loaded from localStorage, subtract it. It still is part of the model to avoid regressions, but should be 0
      sumInvesteringer.investeringer -= tiltakAndre.mva.kostnad;
      //Reset it to ensure it will be 0 when user saves the next time
      tiltakAndre.mva.kostnad = 0;

      //Calculate kapitalskogeier total investment
      sumInvesteringer.skattbareInvesteringer = sumInvesteringer.investeringer - tiltakAndre.kursKompetanse.kostnad - tiltakAndre.forsikring.kostnad;
      sumInvesteringer.skattInvesteringer = sumInvesteringer.skattbareInvesteringer * dataService.data.skogfond.mva;
      sumInvesteringer.totalInvesteringer = sumInvesteringer.investeringer + sumInvesteringer.skattInvesteringer;

      //Skogfondsbehov
      if(dataService.data.user.userType == dataService.USER_TYPES.USER_KAPITAL){
        sumInvesteringer.skogfondsbehov = sumInvesteringer.totalInvesteringer-sumInvesteringer.tilskudd;
      } else {
        sumInvesteringer.skogfondsbehov = sumInvesteringer.investeringer-sumInvesteringer.tilskudd;
      }

      dataService.data.skogfond.kalkulertSkogfondssats = Math.round((sumInvesteringer.skogfondsbehov-dataService.data.skogfond.innestaendeSkogfond)/dataService.data.sumDrift.bruttoinntektHogst*1000)/1000;
      sumInvesteringer.skogfondssats = dataService.data.skogfond.kalkulertSkogfondssats;
      if(sumInvesteringer.skogfondssats > maxSkogfondssats ){
        sumInvesteringer.skogfondssats = maxSkogfondssats;
      }

      dataService.data.sumInvesteringer = sumInvesteringer;

      return sumInvesteringer;
    },

    calculateSkogfond : function(){
      var drift = this.calculateDrift();
      var investeringer = this.calculateInvesteringer();
      var skogfond = dataService.data.skogfond;

      //Ensure ints
      skogfond.innestaendeSkogfond = parseInt( skogfond.innestaendeSkogfond );
      skogfond.avsattSkogfond = parseInt( skogfond.avsattSkogfond );

      if( skogfond.avsattSkogfondssats === 0 ){
        skogfond.avsattSkogfondssats = (investeringer.skogfondssats<0.4?Math.ceil(investeringer.skogfondssats*100)/100:0.4);
      }

      //Round to one decimal point
      skogfond.avsattSkogfondssats = Math.round( skogfond.avsattSkogfondssats * 1000 )/1000;

      skogfond.avsattSkogfond = Math.round(dataService.data.sumDrift.bruttoinntektHogst * skogfond.avsattSkogfondssats);

      var innestaende = parseInt(skogfond.innestaendeSkogfond) + parseInt(skogfond.avsattSkogfond) - parseInt(investeringer.skogfondsbehov);
      if( innestaende < 0 ){
        innestaende = 0;
      }

      skogfond.innestaendeSkogfondEtterInvesteringer = innestaende;

      //Udekkede investeringer
      var udekkede = 0;

      //Different calculation if kapitalskogeier
      if(dataService.data.user.userType == dataService.USER_TYPES.USER_KAPITAL){
        udekkede = parseInt(investeringer.totalInvesteringer) - parseInt( investeringer.tilskudd ) - parseInt(skogfond.avsattSkogfond) - parseInt(skogfond.innestaendeSkogfond);
        skogfond.reellInvesteringsKostnad = (investeringer.totalInvesteringer - investeringer.tilskudd)*(1-1.85*skogfond.skattesats) + investeringer.skattInvesteringer*(1-skogfond.skattesats);
      } else {
        udekkede = parseInt(investeringer.investeringer) - parseInt( investeringer.tilskudd ) - parseInt(skogfond.avsattSkogfond) - parseInt(skogfond.innestaendeSkogfond);
        skogfond.reellInvesteringsKostnad = (investeringer.investeringer - investeringer.tilskudd)*(1-1.85*skogfond.skattesats);
      }

      if( udekkede<0 ){
        udekkede = 0;
      }

      skogfond.udekkedeInvesteringer = udekkede;

      dataService.data.skogfond = skogfond;

      return skogfond;
    },

    calculateOkonomi : function(){
      var skogfond = this.calculateSkogfond();
      var drift = dataService.data.sumDrift;
      var investeringer = dataService.data.sumInvesteringer;

      var skogfondssats = (dataService.data.skogfond.avsattSkogfondssats ? dataService.data.skogfond.avsattSkogfondssats : investeringer.skogfondssats );

      //Refunderbart if avsattSkogfond + innestaende > skogfondsbehov => skogfondsbehov
      var skogfondRefunderbart =
        (dataService.data.skogfond.avsattSkogfond + dataService.data.skogfond.innestaendeSkogfond)>dataService.data.sumInvesteringer.skogfondsbehov ?
        dataService.data.sumInvesteringer.skogfondsbehov : (dataService.data.skogfond.avsattSkogfond + dataService.data.skogfond.innestaendeSkogfond);
      var okonomi = {
        bruttoinntektHogst : dataService.data.sumDrift.bruttoinntektHogst,
        utgifter : dataService.data.sumDrift.hogstKjoring,
        nettoinntekter : 0,
        skogfondskonto : skogfondRefunderbart,
        skattbarSkogfondskonto : skogfondRefunderbart *  0.15,
        investeringer : (dataService.data.user.userType == dataService.USER_TYPES.USER_KAPITAL) ? dataService.data.sumInvesteringer.totalInvesteringer : dataService.data.sumInvesteringer.investeringer,
        tilskudd : dataService.data.sumInvesteringer.tilskudd,
        hogstTilskudd: dataService.data.sumDrift.hogstTilskudd,
        utbetalingSkogfond : 0,
        marginalSkatt : dataService.data.skogfond.skattesats,
        skattbarInntekt : 0,
        skatt : 0,
        nettoEtterSkatt : 0,
        paKonto : 0,
        mva : 0
      };



      okonomi.nettoinntekter = okonomi.bruttoinntektHogst - okonomi.utgifter - (skogfond.avsattSkogfond || 0);
      okonomi.utbetalingSkogfond = okonomi.skogfondskonto * 0.85;
      if(dataService.data.user.userType == dataService.USER_TYPES.USER_KAPITAL){
        //okonomi.mva = (okonomi.nettoinntekter - okonomi.investeringer) * dataService.data.skogfond.mva;
        okonomi.mva = dataService.data.sumDrift.skattInntekt - dataService.data.sumDrift.skattHogstKjoring - dataService.data.sumInvesteringer.skattInvesteringer;
      }
      var skattbarInntekt = okonomi.skattbarSkogfondskonto + okonomi.tilskudd + okonomi.nettoinntekter + okonomi.mva - okonomi.investeringer + okonomi.hogstTilskudd;
      okonomi.skattbarInntekt = skattbarInntekt;
      //okonomi.skattbarInntekt = (skattbarInntekt<0 ? 0 : skattbarInntekt);
      okonomi.skatt = okonomi.skattbarInntekt * okonomi.marginalSkatt;
      okonomi.nettoEtterSkatt = okonomi.skattbarInntekt - okonomi.skatt;
      okonomi.paKonto = okonomi.utbetalingSkogfond + okonomi.nettoEtterSkatt;


      return okonomi;


    }
  };
});
