'use strict';

/**
 * @ngdoc directive
 * @name skogkursSkogfondApp.directive:updateInput
 * @description
 * # updateInput
 */
angular.module('skogkursSkogfondApp')
  .directive('updateInput', function ( $timeout ) {
    return {      
      restrict: 'A',
      scope : {
        ngModel : '='
      },
      link: function postLink(scope, element, attrs) {        
        element.bind( 'blur', function(){          
          if( !angular.isUndefined(scope.ngModel) ){
            if( !scope.ngModel ){              
              $timeout( function(){
                scope.ngModel = 0;
              });              
            }
          }
        });

        element.bind( 'focus', function(){          
          if( !angular.isUndefined(scope.ngModel) ){
            if( scope.ngModel === 0 ){              
              $timeout( function(){
                scope.ngModel = '';  
              });              
            }
          }
        });
      }
    };
  });
