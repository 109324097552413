'use strict';

/**
 * @ngdoc function
 * @name skogkursSkogfondApp.controller:AboutCtrl
 * @description
 * # AboutCtrl
 * Controller of the skogkursSkogfondApp
 */
angular.module('skogkursSkogfondApp')
  .controller('AboutCtrl', function ($scope) {
    jQuery( 'ul.nav li' ).removeClass( 'active' );
    jQuery( '.navbar-collapse.collapse').removeClass('in');
  });
