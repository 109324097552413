'use strict';

/**
 * @ngdoc function
 * @name skogkursSkogfondApp.controller:IndexCtrl
 * @description
 * # IndexCtrl
 * Controller of the skogkursSkogfondApp
 */
angular.module('skogkursSkogfondApp')
  .controller('IndexCtrl', function ( persistService, dataService, $scope ) {
    jQuery( 'ul.nav' ).hide();
    jQuery( 'ul.nav li' ).removeClass( 'active' );
    jQuery( '.navbar-collapse.collapse').removeClass('in');

    $scope.setUserKapital = function(){
      dataService.setUserType( dataService.USER_TYPES.USER_KAPITAL );
    };

    $scope.setUserVirksomhet = function(){
      dataService.setUserType( dataService.USER_TYPES.USER_VIRKSOMHET );
    };
  });
