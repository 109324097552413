'use strict';

/**
 * @ngdoc function
 * @name skogkursSkogfondApp.controller:InvesteringerCtrl
 * @description
 * # InvesteringerCtrl
 * Controller of the skogkursSkogfondApp
 */
angular.module('skogkursSkogfondApp')
  .controller('InvesteringerCtrl', function ($scope, dataService, calculationService, persistService ) {
  	$scope.modes = {
  		SKOGSVEIER : 'skogsveier',
  		SKOGKULTUR : 'skogkultur',
  		ANNET : 'annet'
  	};

  	$scope.tiltakSkogkultur = {};
  	$scope.tiltakSkogsveier = {};
  	$scope.tiltakAndre = {};
    $scope.mode = $scope.modes.SKOGKULTUR;
    $scope.user = dataService.data.user;
    $scope.userTypes = dataService.USER_TYPES;

  	$scope.$watch( 'tiltakSkogkultur', function( nv ){
  		dataService.data.tiltakSkogkultur = $scope.tiltakSkogkultur;
  		$scope.sumInvesteringer = calculationService.calculateInvesteringer();
  	}, true);

  	$scope.$watch( 'tiltakSkogsveier', function( nv ){
  		dataService.data.tiltakSkogsveier = $scope.tiltakSkogsveier;
  		$scope.sumInvesteringer = calculationService.calculateInvesteringer();
  	}, true);

  	$scope.$watch( 'tiltakAndre', function( nv ){
  		dataService.data.tiltakAndre = $scope.tiltakAndre;
  		$scope.sumInvesteringer = calculationService.calculateInvesteringer();
  	}, true);



	  $scope.tiltakSkogkultur = dataService.data.tiltakSkogkultur;

	  $scope.tiltakSkogsveier = dataService.data.tiltakSkogsveier;

	  $scope.tiltakAndre = dataService.data.tiltakAndre;

    jQuery( 'ul.nav' ).show();
	  jQuery( 'ul.nav li' ).removeClass( 'active' );
    jQuery( 'li#nav-investeringer' ).addClass( 'active' );
    jQuery( '.navbar-collapse.collapse').removeClass('in');
  });
