'use strict';

/**
 * @ngdoc service
 * @name skogkursSkogfondApp.dataService
 * @description
 * # dataService
 * Service in the skogkursSkogfondApp.
 */
angular.module('skogkursSkogfondApp')
  .service('dataService', function () {
    const USER_TYPES = {
      USER_VIRKSOMHET : 'Virksomhetsskogbruk',
      USER_KAPITAL : 'Kapitalskogbruk'
    }

    var user = {
      userType : USER_TYPES.USER_VIRKSOMHET
    }

    var base = {

      hogsts: [{
            number : 1,
            volum : 0,
            virkespris : 0,
            utgifter : 0,
            tilskudd: 0
      }],


      tiltakSkogkultur : {
        markbredning            : {
          key : 'Markberedning',
          areal: 0,
          kostnad: 250,
          tilskudd: 0
        },
        klargjoringPlantefelt   : {
          key : 'Klargjøring plantefelt',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        vegetasjonskontroll     : {
          key : 'Vegetasjonskontroll',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        planting                : {
          key : 'Planting',
          areal: 0,
          kostnad: 1000,
          tilskudd: 0
        },
        saing                   : {
          key : 'Såing',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        suppleringsplanting     : {
          key : 'Suppleringsplanting',
          areal: 0,
          kostnad: 1000,
          tilskudd: 0
        },
        etableringJuletre       : {
          key : 'Etablering juletre',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        ungskogpleie            : {
          key : 'Ungskogpleie',
          areal: 0,
          kostnad: 350,
          tilskudd: 0
        },
        stammekvisting          : {
          key : 'Stammekvisting',
          areal: 0,
          kostnad: 600,
          tilskudd: 0
        },
        tynningForhandsrydding  : {
          key : 'Tynning / forhåndsrydding',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        tynningUnderskudd     : {
          key : 'Tynning underskudd',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        gjodsling               : {
          key : 'Gjødsling',
          areal: 0,
          kostnad: 300,
          tilskudd: 0
        },
        skadeforebyggende       : {
          key : 'Skadeforebyggende',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        grofterensk             : {
          key : 'Grøfterensk',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        },
        suppleringsgrofting     : {
          key : 'Suppleringsgrøfting',
          areal: 0,
          kostnad: 0,
          tilskudd: 0
        }
      },

      tiltakSkogsveier : {
        nybyggingVei            : {
          key : 'Nybygging vei',
          lengde : 0,
          kostnad : 500,
          tilskudd : 0
        },
        vedlikeholdVei          : {
          key : 'Vedlikehold vei',
          lengde : 0,
          kostnad : 4,
          tilskudd : 0
        },
        ombyggingVei            : {
          key : 'Ombygging vei',
          lengde : 0,
          kostnad : 500,
          tilskudd : 0
        }
      },

      tiltakAndre : {
        miljotiltak             : {
          key : 'Miljøtiltak',
          kostnad : 0,
          tilskudd : 0
        },
        skogbruksplanlegging    : {
          key : 'Skogbruksplanlegging',
          kostnad : 0,
          tilskudd : 0
        },
        forsikring              : {
          key : 'Forsikring',
          kostnad : 0,
          tilskudd : 0
        },
        bioenergitiltak         : {
          key : 'Bioenergitiltak',
          kostnad : 0,
          tilskudd : 0
        },
        kursKompetanse          : {
          key : 'Kurs og kompetanse',
          kostnad : 0,
          tilskudd : 0
        },
        grensemerking           : {
          key : 'Grensemerking',
          kostnad : 0,
          tilskudd : 0
        },
        mva                     : {
          key : 'Mva',
          kostnad : 0,
          tilskudd : 0
        }
      },


      skogfond : {
        avsattSkogfondssats : 0,
        avsattSkogfond : 0,
        innestaendeSkogfond : 0,
        innestaendeSkogfondEtterInvesteringer : 0,
        udekkedeInvesteringer : 0,
        skogfondRefunderbart : 0,
        reellInvesteringsKostnad : 0,
        skattesats : 0.22,
        mva : 0.25
      },

      sumDrift : {
        bruttoinntektHogst  : 0,
        hogstKjoring        : 0,
        hogstTilskudd       : 0,
        totaltHogstVolum    : 0,
        snittVirkespris     : 0,
        snittUtgifter       : 0,
        skattInntekt        : 0,
        skattHogstKjoring   : 0,
        totalInntekt        : 0,
        totalHogstKjoring   : 0
      },



      sumInvesteringer : {
        investeringer    : 0,
        tilskudd         : 0,
        skogfondssats    : 0,
        skogfondsbehov   : 0,
        skattbareInvesteringer: 0,
        skattInvesteringer : 0,
        totalInvesteringer : 0
      },

      user : user
    };


    var data = base;

    return {
      data : data,
      setData : function( data ){
        this.data = angular.merge(this.data, data);
      },
      setUserType : function( userType ){
        this.data.user.userType = userType;

        //If kapitalskogeier enforce 0.22 tax
        if( this.data.user.userType == this.USER_TYPES.USER_KAPITAL ){
          this.data.skogfond.skattesats = 0.22;
        }
      },
      USER_TYPES : USER_TYPES
    };
  });
