'use strict';

/**
 * @ngdoc function
 * @name skogkursSkogfondApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the skogkursSkogfondApp
 */
angular.module('skogkursSkogfondApp')
  .controller('MainCtrl', function ( persistService, dataService, $scope ) {
    jQuery( 'ul.nav' ).show();
    jQuery( 'ul.nav li' ).removeClass( 'active' );
    jQuery( '.navbar-collapse.collapse').removeClass('in');

    $scope.user = dataService.data.user;
    $scope.userTypes = dataService.USER_TYPES;
  });
