'use strict';

/**
 * @ngdoc service
 * @name skogkursSkogfondApp.informationService
 * @description
 * # informationService
 * Service in the skogkursSkogfondApp.
 */
angular.module('skogkursSkogfondApp')
  .service('informationService', function () {
    // AngularJS will instantiate a singleton by calling "new" on this function
    var infos = {
      drift : {
        hogstVolum : {
          headline : 'Hogstvolum',
          body : 'Totalt volum i den planlagte hogsten'
        },
        virkespris : {
          headline : 'Virkespris',
          body : 'Beregn gjennomsnittlig verdi pr. m<sup>3</sup> av det planlagte hogstkvantumet'
        },
        hogstUtgifter : {
          headline : 'Utgifter hogst',
          body : 'Kostnaden pr. m<sup>3</sup> for hogst og framkjøring av den planlagte hogsten'
        },
        hogstTilskudd : {
          headline : 'Driftstilskudd',
          body : 'Et tilskudd til hogst og framkjøring av tømmer'
        },
        bruttoInntekt : {
          headline : 'Bruttoinntekt',
          body : 'Summen viser beregnet bruttoinntekt. Volum x virkespris'
        },
        kostnadHogstKjoring : {
          headline : 'Sum kostnad hogst og kjøring',
          body : 'Summen viser beregnet kostnad hogst og kjøring. Volum x driftspris'
        },
        kostnadTilskudd : {
          headline : 'Sum driftstilskudd',
          body : 'Summen viser beregnet driftstilskudd. Volum x driftstilskudd'
        }
      },



      investeringer : {
        general : {
          headline : 'Investeringer',
          body : 'Tilskudds prosent varierer mellom ulike kommuner og tiltak. Logg inn på din skogfondskonto, eller sjekk med din kommune!'
        },
        markberedning : {
          headline : 'Markberedning',
          body : 'Kostnader til markberedning for å bedre vekstvilkårene for frø eller planter'
        },
        plantefelt : {
          headline : 'Klargjøring av plantefelt',
          body : 'Kostnader til flaterydding eller andre tiltak av hogstfelt når dette er nødvendig for å få opp tilfredsstillende foryngelse'
        },
        planting : {
          headline : 'Planting',
          body : 'Kostnader til plantekjøp og planting'
        },
        vegetasjonskontroll : {
          headline : 'Vegetasjonskontroll',
          body : 'Kostnader til fjerning av gras og vegetasjon som hindrer plantenes vekst i foryngelsesfasen'
        },
        suppleringsplanting : {
          headline : 'Suppleringsplanting',
          body : 'Kostnader til nødvendig suppleringsplanting og plantekjøp'
        },
        ungskogpleie : {
          headline : 'Ungskogpleie',
          body : 'Kostnader til å fristille et riktig antall av de trærne en vil ha med videre i produksjonen'
        },
        stammekvisting : {
          headline : 'Stammekvisting ',
          body : 'Kostnader til stammekvisting som er foretatt for å øke kvaliteten på tømmeret'
        },
        gjodsling : {
          headline : 'Gjødsling',
          body : 'Kostnader til gjødsling for å øke skogproduksjonen'
        },
        tynningForhandsrydding : {
          headline : 'Tynning-forhåndsrydding',
          body : 'Kostnad til å fjerne uønsket vegetasjon eller ikke økonomisk drivverdige dimensjoner før tynning'
        },
        tynningUnderskudd : {
          headline : 'Tynning- underskudd',
          body : 'Dersom tynningen går i underskudd kan underskuddet dekket med skogfond'
        },
        saing : {
          headline : 'Såing',
          body : 'Kostnader til såing og kjøp av frø'
        },
        skadeforebyggende : {
          headline : 'Skadeforebyggende',
          body : 'Kostnader til f.eks. stubbebehandling mot råte og inngjerding mot beiteskader'
        },
        grofterensk : {
          headline : 'Grøfterensk',
          body : 'Kostnader til vedlikehold og rensking av gamle grøftesystemer'
        },
        suppleringsgrofting : {
          headline : 'Suppleringsgrøfting',
          body : 'Kostnad til supplering av eksisterende grøftefelter'
        },
        etableringJuletre : {
          headline : 'Etablering av juletre',
          body : 'Kostnader til etablering av juletrefelt. Forarbeid, plantekjøp, etterarbeid i etableringsåret og det påfølgende år'
        },
        nybygg : {
          headline: 'Nybygg',
          body : 'Kostnad til nybygg av veier i henhold til Forskrift om Landbruksveier.<br>OBS! Søknadspliktig tiltak'
        },
        vedlikehold : {
          headline: 'Vedlikehold',
          body : 'Kostnader til sommervedlikehold av bilveger'
        },
        ombygging : {
          headline: 'Ombygging',
          body : 'Kostnader til ombygging av veg som resulterer i bedring av vegstandard. OBS! Søknadspliktig tiltak'
        },
        miljotiltak : {
          headline: 'Miljøtiltak',
          body : 'Kostnader til tiltak som fremmer miljøverdier. For eksempel spesiell skjøtsel av kantsoner, kulturminner m.v.'
        },
        skogbruksplanlegging : {
          headline: 'Skogbruksplanlegging',
          body : 'Kostnader til skogbruksplan med miljøregistreringer'
        },
        forsikring : {
          headline: 'Forsikring',
          body : 'Kostnader til skogforsikring'
        },
        bioenergi : {
          headline: 'Bioenergi',
          body : 'Kostnader til varige investeringer i anlegg for salg av varme'
        },
        kursKompetanse : {
          headline: 'Kurs og kompetanse',
          body : 'Kostnader til kurs og kompetansetiltak innen drift og forvaltning av skog'
        },
        grensemerking : {
          headline: 'Grensemerking',
          body : 'Kostnader til grensemerking i forbindelse med jordskifte'
        },
        mva : {
          headline: 'Merverdiavgift',
          body : 'Kostnader til merverdiavgift for merverdiavgift registrert skogeier kan føres her. OBS! – Ikke skattefordel'
        }
      },




      minOkonomi : {
        skogfondsbehov : {
          headline: 'Skogfondsbehov',
          body : 'Summert behov for skogfond av valgte investeringer'
        },
        skogfondssats : {
          headline: 'Kalkulert skogfondssats',
          body : 'Kalkulert behov for avsetning til skogfond. Du velger selv sats som du ønsker å sette av for videre beregning. Avsetning 4-40% av virkes verdi.'
        },
        skattesats : {
          headline: 'Skattesats',
          body : 'Velg marginal skattesats.<br> ' +
            'Hva er skatten på siste krone du tjener?' +
            '<ul>' +
              '<li>22% representerer de som kun har kapitalinntekt</li>' +
              '<li>33% representerer de som har personinntekt lavere enn 208 049 kroner</li>' +
              '<li>34,7% representerer de som har personinntekt 208 050 - 292 849 kroner</li>' +
              '<li>37% representerer de som har personinntekt 292 850- 669 999 kroner</li>' +
              '<li>46,6% representerer de som har personinntekt 670 000- 937 899 kroner</li>' +
              '<li>49,6% representerer de som har personinntekt 937 900- 1 349 999 kroner</li>' +
              '<li>50,6% representerer de som har personinntekt 1 350 000 kroner eller mer</li>' +
            '</ul>'
        }
      }
    };

    return {
      //Find info based on namespace
      getInfo : function( module, key ){
        var info = false;
        if( !angular.isUndefined(infos[module])){
          if( !angular.isUndefined( infos[module][key] ) ){
            info = infos[module][key];
          }
        }
        return info;
      },
      getInfos : function(){
        return infos;
      }
    };
  });
