'use strict';

/**
 * @ngdoc filter
 * @name skogkursSkogfondApp.filter:formatAmount
 * @function
 * @description
 * # formatAmount
 * Filter in the skogkursSkogfondApp.
 */
angular.module('skogkursSkogfondApp')
  .filter('formatAmount', function () {
    return function (input) {
      //Reg ex to format amounts (Thousands seperators etc.)
      if( angular.isString( input ) ){
        input = parseFloat( input );
      }
      return input.toFixed(0).replace(/(?=(?:\d{3})+$)(?!^)/g, ' ');
    };
  })
  .filter('percentagePointsToPercent', function(){
    return function( input ){
        //One decimal
        return Math.round( input * 1000 )/10;
    };
  });
