'use strict';

/**
 * @ngdoc function
 * @name skogkursSkogfondApp.controller:SkogfondCtrl
 * @description
 * # SkogfondCtrl
 * Controller of the skogkursSkogfondApp
 */
angular.module('skogkursSkogfondApp')
  .controller('SkogfondCtrl', function ($scope, calculationService, dataService, $filter ) {
    var ctrl = this;
    this.calculate = function(){
      $scope.okonomi = calculationService.calculateOkonomi();
      $scope.drift = dataService.data.sumDrift;
      $scope.skogfond = dataService.data.skogfond;
      $scope.investeringer = dataService.data.sumInvesteringer;
      $scope.user = dataService.data.user;
      $scope.userTypes = dataService.USER_TYPES;

      $scope.kalkulertSkogfondssats = $filter('percentagePointsToPercent')($scope.investeringer.skogfondssats);
      if( !$scope.avsattSkogfondssats ){
        $scope.avsattSkogfondssats = $filter('percentagePointsToPercent')($scope.skogfond.avsattSkogfondssats);
      }
    };

    this.calculate();

    jQuery( 'ul.nav' ).show();
    jQuery( 'ul.nav li' ).removeClass( 'active' );
    jQuery( 'li#nav-skogfond' ).addClass( 'active' );
    jQuery( '.navbar-collapse.collapse').removeClass('in');

    $scope.$watch( 'avsattSkogfondssats', function( nv, ov){
      var sats = parseFloat( nv );
      if (sats > 40) {
        sats = 40;
      } else if (sats < 4) {
        sats = 4;
      }
      $scope.skogfond.avsattSkogfondssats = sats / 100;
      $scope.avsattSkogfondssats = $filter('percentagePointsToPercent')($scope.skogfond.avsattSkogfondssats);
    });

    $scope.$watch( 'skogfond', function( nv, ov ){
      dataService.data.skogfond = $scope.skogfond;
      ctrl.calculate();
    }, true);

  });
