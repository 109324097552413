'use strict';

/**
 * @ngdoc directive
 * @name skogkursSkogfondApp.directive:info
 * @description
 * # info
 */
angular.module('skogkursSkogfondApp')
  .directive('info', function ( informationService ) {
    return {      
      restrict: 'A',
      link: function postLink(scope, element, attrs) {
        var info = false;
        
        //attrs.info should be on format module.key
        if( angular.isString(attrs.info) ){
          var keys = attrs.info.split( '.' );
          if( keys.length === 2 ){
            info = informationService.getInfo( keys[0], keys[1] );
            var placement = (attrs.placement || 'bottom');
            jQuery(element).popover({
              title : info.headline,
              content : info.body,
              placement : placement,              
              container : 'body',
              html : true,
              trigger : 'hover'
            });
          }else{
            console.log( 'Invalid length on keys', keys.length );
          }
        }else{
          console.log( 'No valid info id', attrs.info );
        }
      }
    };
  });
