'use strict';

/**
 * @ngdoc function
 * @name skogkursSkogfondApp.controller:DriftCtrl
 * @description
 * # DriftCtrl
 * Controller of the skogkursSkogfondApp
 */
angular.module('skogkursSkogfondApp')
  .controller('DriftCtrl', function ($scope, calculationService, dataService, persistService) {

    $scope.hogsts = dataService.data.hogsts;
    $scope.skattesats = dataService.data.skogfond.skattesats;
    $scope.user = dataService.data.user;
    $scope.userTypes = dataService.USER_TYPES;

    $scope.$watch( 'hogsts', function( nv, ov ){
        dataService.data.hogsts = $scope.hogsts;
        $scope.driftSums = calculationService.calculateDrift();
    }, true);

    $scope.log = function(event){
      console.log( event );
    };


    $scope.addHogst = function(){
        var number = 1;

        if( $scope.hogsts.length>0 ){
            number = $scope.hogsts[$scope.hogsts.length-1].number + 1;
        }
        $scope.hogsts.push( {
            number : number,
            volum : 0,
            virkespris : 0,
            utgifter : 0,
            tilskudd : 0
        } );
    };

    $scope.removeHogst = function( number ){
        for( var i=0; i<$scope.hogsts.length; i++ ){
            if( $scope.hogsts[i].number === number ){
                $scope.hogsts.splice( i, 1 );
                return true;
            }
        }

        return false;
    };

    jQuery( 'ul.nav' ).show();
    jQuery( 'ul.nav li' ).removeClass( 'active' );
    jQuery( 'li#nav-drift' ).addClass( 'active' );
    jQuery( '.navbar-collapse.collapse').removeClass('in');
  });
