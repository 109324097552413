'use strict';

/**
 * @ngdoc directive
 * @name skogkursSkogfondApp.directive:userMode
 * @description
 * # userMode
 */
angular.module('skogkursSkogfondApp')
  .directive('userMode', function ( dataService ) {
    return {
      restrict: 'E',
      template: '<div class="sf-owner-type">{{user.userType}}</div>',
      link: function ( scope ){
        scope.user = dataService.data.user;
      }
    }
  });

