'use strict';

/**
 * @ngdoc function
 * @name skogkursSkogfondApp.controller:MinKonomiCtrl
 * @description
 * # MinKonomiCtrl
 * Controller of the skogkursSkogfondApp
 */
angular.module('skogkursSkogfondApp')
  .controller('ResultatCtrl', function ($scope, $rootScope, calculationService, dataService, persistService, $http) {
    $scope.modes = {
      OVERSIKT : 'oversikt',
      TALL : 'tallsammendrag',
      EPOST : 'sendEpost'
    };

    $scope.config = {
      mailendpoint : '/mail.php'
    };


    //Get external config
    $http.get( '/skogfondconfig.json' ).then( function( response ){
      const data = response.data;
      if( response.data.mailendpoint ){
        $scope.config.mailendpoint = data.mailendpoint;
      }
    }, function( error ){
      console.log( 'Error getting skogfond config' );
    });



    $scope.mode = $scope.modes.OVERSIKT;

    $scope.okonomi = calculationService.calculateOkonomi();
    $scope.sumDrift = dataService.data.sumDrift;
    $scope.sumInvesteringer = dataService.data.sumInvesteringer;
    $scope.skogfond = dataService.data.skogfond;
    $scope.user = dataService.data.user;
    $scope.userTypes = dataService.USER_TYPES;
    //$scope.investeringsKostnad = ($scope.sumInvesteringer.investeringer - $scope.sumInvesteringer.tilskudd)*(-0.85*$scope.skogfond.skattesats)

    $scope.valgteInvesteringer = {
      skogkultur : [],
      skogsveier : [],
      andre : []
    };
    $scope.emailBody = '';

    $scope.prepareEmail = function(){
      var html = jQuery( '#result-panel' ).html();
      $scope.emailBody = html;
    };

    $scope.storeData = function(){
      persistService.persistData();
    };

    $scope.hasUpdates = persistService.hasUpdates;
    $scope.hasPersistedData = persistService.hasPersistedData;
    $scope.clearData = persistService.clearData;


    //Add selected investments
    var tiltak = {};
    for ( var key in dataService.data.tiltakSkogkultur ){
      if( dataService.data.tiltakSkogkultur.hasOwnProperty( key ) ){
        tiltak = dataService.data.tiltakSkogkultur[ key ];
        if( tiltak.areal>0 ){
          $scope.valgteInvesteringer.skogkultur.push( tiltak );
        }
      }
    }

    for( key in dataService.data.tiltakSkogsveier ){
      if( dataService.data.tiltakSkogsveier.hasOwnProperty( key ) ){
        tiltak = dataService.data.tiltakSkogsveier[ key ];
        if( tiltak.lengde>0 ){
          $scope.valgteInvesteringer.skogsveier.push( tiltak );
        }
      }
    }

    for( key in dataService.data.tiltakAndre ){
      if( dataService.data.tiltakAndre.hasOwnProperty( key ) ){
        tiltak = dataService.data.tiltakAndre[ key ];
        if( tiltak.kostnad>0 ){
          $scope.valgteInvesteringer.andre.push( tiltak );
        }
      }
    }

    $scope.email = '';
    $scope.emailSuccess = false;
    $scope.emailError = false;


    jQuery( 'ul.nav' ).show();
    jQuery( 'ul.nav li' ).removeClass( 'active' );
    jQuery( 'li#nav-resultat' ).addClass( 'active' );
    jQuery( '.navbar-collapse.collapse').removeClass('in');

    jQuery( '#email-form' ).on( 'submit', function( event ){
      var jthis = jQuery( this );
      $scope.$apply( function(){
        $scope.emailError = false;
        $scope.emailSuccess = false;
      });

      //Ajax submit the form
      jQuery.ajax({
        url : jthis.attr( 'action' ),
        method : jthis.attr( 'method' ),
        data : jthis.serialize(),
        success : function( data ){
          $scope.$apply( function(){
            $scope.emailError = false;
            $scope.emailSuccess = true;
          });
        },
        error : function( data ){
          console.log( 'Data error', data );
          $scope.$apply( function(){
              $scope.emailError = true;
              $scope.emailSuccess = false;
            });
        }
      });
      return false;
    });
  });
